<template>
  <van-overlay
      :show="loadingOfDeviceDetails || loadingOfDeviceLogs || loadingOfDeviceManufacturer || loadingOfDeviceCustomer || loadingOfDeviceCustomerDepartment"
      @click="show = false">
    <div class="wrapper" @click.stop>
      <van-loading type="spinner" color="#1989fa"/>
    </div>
  </van-overlay>
  <div>
    <van-nav-bar fixed border left-arrow title="设备详情" left-text="返回" @click-left="router.back"/>
    <div class="page-container">
      <van-cell-group title="设备信息" inset>
        <van-field label="设备名称" readonly v-model="device.device.name"/>
        <van-field label="出厂序列号" readonly v-model="device.device.sn"/>
        <van-field label="设备型号" readonly v-model="device.device.model"/>
        <van-field label="设备制造商" readonly v-model="device.manufacturer.name"/>
        <van-field label="服务起始时间" readonly v-model="device.device.installTime"/>
        <van-field label="服务到期时间" readonly v-model="device.device.warrantyPeriod"/>
        <van-field label="地址信息" readonly v-model="device.device.xxdz"/>
        <van-field label="楼号楼层及门牌号" readonly v-model="device.device.storey"/>
        <van-field label="备注" readonly v-model="device.device.description"/>
      </van-cell-group>

      <van-cell-group title="设备所属" inset>
        <van-field label="责任人" readonly v-model="device.customer.name"/>
        <van-field label="地址" readonly v-model="device.customer.address"/>
        <van-field label="联系方式" readonly v-model="device.customer.cellphone"/>
        <van-field label="单位" readonly v-model="device.customerDepartment.name"/>
      </van-cell-group>

      <smp-file-cell-group :files="device.device.files"/>

      <van-cell-group title="所在位置" inset style="padding: 16px">
        <smp-map :markers="device.device.markers" ref="mapRef"/>
      </van-cell-group>

      <van-cell-group title="日志" inset>
        <van-steps direction="vertical" :active="device.logs.length-1">
          <van-step v-for="item in device.logs" :key="item.id">
            {{ item.createTime }} {{ item.log }}
          </van-step>
        </van-steps>
      </van-cell-group>
    </div>
  </div>
</template>

<script setup>
/**
 * 设备详情页面
 * @author Wayne Carder
 */
import {onBeforeMount, onMounted, reactive, ref} from "vue";
import {useRouter} from "vue-router";
import {Toast} from "vant";

import SmpFileCellGroup from "@/component/SmpFileCellGroup";
import SmpMap from "@/component/SmpMap";

import {queryDevice, queryDeviceLogs, queryManufacturer} from "@/api/device";
import {queryCustomer, queryCustomerDepartment} from "@/api/customer";
import {nameEncryption, phoneEncryption} from "../../plugin/encryption";
import dd from "gdt-jsapi";

onBeforeMount(validationUrlParamStrictly);
const urlParam = getUrlParam();
const router = useRouter();
const device = reactive({
  device: {},
  logs: [],
  manufacturer: {},
  customer: {},
  customerDepartment: {}
});
const loadingOfDeviceDetails = ref(false);
const loadingOfDeviceLogs = ref(false);
const loadingOfDeviceManufacturer = ref(false);
const loadingOfDeviceCustomer = ref(false);
const loadingOfDeviceCustomerDepartment = ref(false);
const mapRef = ref();

const requestDevice = () => {
  loadingOfDeviceDetails.value = true;
  queryDevice(urlParam.id).then(res => {
    device.device = res.data;
    device.device.xxdz = res.data.province + "-" + res.data.city + "-" + res.data.county + "-" + res.data.address;
    requestDeviceLogs();
    requestDeviceManufacturer();
    requestDeviceCustomer();
    requestDeviceCustomerDepartment();
    loadingOfDeviceDetails.value = false;
    // mapRef.value.initMap();
    res.data.markers = [new AMap.Marker({position: new AMap.LngLat(res.data.longitude, res.data.latitude)})];
  }).catch(err => {
    loadingOfDeviceDetails.value = false;
    Toast.fail(err.message);
    console.error(err);
  });
};

const requestDeviceLogs = () => {
  loadingOfDeviceLogs.value = true;
  queryDeviceLogs(urlParam.id).then(res => {
    device.logs = res.data;
    loadingOfDeviceLogs.value = false;
  }).catch(err => {
    loadingOfDeviceLogs.value = false;
    Toast.fail(err.message);
  });
};

const requestDeviceManufacturer = () => {
  loadingOfDeviceManufacturer.value = true;
  queryManufacturer(device.device.deviceType.manufacturer.id).then(res => {
    device.manufacturer = res.data;
    loadingOfDeviceManufacturer.value = false;
  }).catch(err => {
    loadingOfDeviceManufacturer.value = false;
    Toast.fail(err.message);
  });
};

const requestDeviceCustomer = () => {
  loadingOfDeviceCustomer.value = true;
  queryCustomer(device.device.customerId).then(res => {
    res.data.name = nameEncryption(res.data.name);
    res.data.cellphone = phoneEncryption(res.data.cellphone);
    device.customer = res.data;
    loadingOfDeviceCustomer.value = false;
  }).catch(err => {
    loadingOfDeviceCustomer.value = false;
    Toast.fail(err.message);
  });
};

const requestDeviceCustomerDepartment = () => {
  loadingOfDeviceCustomerDepartment.value = true;
  queryCustomerDepartment(device.device.customerDepartmentId).then(res => {
    device.customerDepartment = res.data;
    loadingOfDeviceCustomerDepartment.value = false;
  }).catch(err => {
    Toast.fail(err.message);
    loadingOfDeviceCustomerDepartment.value = false;
  });
};

onMounted(() => {
  validationUrlParamStrictly();
  requestDevice();
  dd.hideTitleBar()
});
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
