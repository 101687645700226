<template>
  <div id="smp-map-container"></div>
</template>

<script setup>
import {onMounted, onUnmounted, onUpdated} from "vue";

const props = defineProps({
  mapProps: {
    type: Object, required: false, default: {
      viewMode: "3D",
      terrain: true,
      zoom: 13,
      zoomEnable: false,
      dragEnable: false,
      resizeEnable: false
    }
  },
  markers: {type: Array, default: []}
});

let map;
let marker;

onMounted(() => {
  map = new AMap.Map("smp-map-container", props.mapProps);
  map.add(props.markers);
});

onUpdated(() => {
  if (!!map) {
    map.clearMap();
    map.add(props.markers);
    map.setFitView();
  }
});

onUnmounted(() => {
  if (!!map) {
    map.destroy();
  }
});
</script>

<style scoped>
#smp-map-container {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 200px;
}
</style>
